<template>
	<div v-bind:class="loaded?'m-body':'m-body -before'">
		<div class="-flex">
			<div class="-wrapleft">
				<div class="-logo"></div>
			</div>
			<div class="-wrapright -flex0">
				<el-form class="-ts5 m-login" @submit.native="checkSubmit()" @submit.native.prevent>
					<div class="-logintitle">欢迎使用商户版</div>
					<div class="-flex -line -flex-ycenter -mt70 ">
						<text class='-icmy -flex0 -mt15'></text>
						<el-input placeholder="请输入商户全称" prefix-icon="el-icon-user-solid" type="text" v-model="form.company"></el-input>
					</div>
					<div class="-flex -line -flex-ycenter -mt20">
						<text class='-ictag -flex0 -mt15'></text>
						<el-input placeholder="请输入手机号码" prefix-icon="el-icon-user-solid" type="text" v-model="form.uname"></el-input>
					</div>
					<div class="-flex -line -flex-ycenter -mt20">
						<div class='-iclock -flex0 -mt15'></div>
						<el-input placeholder="请输入登录密码" prefix-icon="el-icon-lock" type="password" v-model="form.upwd"></el-input>
					</div>
					<el-button type="primary" native-type="submit">登 录</el-button>
				</el-form>
			</div>
		</div>
			
	</div>
</template>

<script>
export default {
	data () {
		return {
			loaded: false,
			form: {
				company: '',
				uname: '',
				upwd: ''
			}
		}
	},
	methods: {
		checkSubmit: function(e){
			
			if(this.form.company == '' || this.form.company.length<4) return this._msg('请输入正确的商户名称')
			if(this.form.uname == '' || this.form.uname.length<11) return this._msg('请输入正确的手机号')
			if(this.form.upwd == '') return this._msg('请输入登录密码')
			if(this.form.upwd.length<6) return this._msg('请输入正确的登录密码')
			
			this._post('/System/User/login', this.form, (res)=>{
				this._msg('登录成功');
				this._user.accessToken = res.data.accessToken
				this._user.userToken   = res.data.userToken
				this._user.mobile      = res.data.mobile
				this._user.name        = res.data.name
				this._user.roleId      = res.data.roleId
				this._user.webAuths    = res.data.webAuths
				localStorage.setItem( this._app.name +'_'+ this._app.appId, JSON.stringify(this._user) )
				this.$router.push({ path: '/'})
			})

			return false;
		}
	},
	created () {
		setTimeout(()=>{
			this.loaded = true
		}, 100)
	},
}
</script>

<style>
.m-body { width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center;  background: center no-repeat url(../../assets/image/bg1.png); background-size: cover;z-index: 1;}
.m-body .-center{width: 990px; height: 880px;position: relative;background: url(../../assets/image/img2.png) no-repeat center; background-size: cover;display: flex; flex-direction: column; justify-content: center; align-items: center;}
.m-body .-maptitle{width: 480px; height:42px;position:absolute;top:50px ;background: url(../../assets/image/title1.png) no-repeat center; background-size: cover;}
.m-body .m-login { width: 60%; height: auto; }
.m-body .m-login .-logintitle{color: #000000;font-size: 24px;float: left;}
.m-body .m-login .-line{border-bottom: 1px solid #dddddd;width:100%}
.m-body .m-login .m-title { margin-top: 5px; font-size: 16px; padding-bottom: 30px; color: #c4daf5;}
.m-body .m-login .el-input { margin-top: 18px;background: 0 ;border: none;}
.m-body .m-login .el-button { width: 100%; margin-top: 60px; background-image: linear-gradient(to right ,#5858f8,#3a49fb); border: 1px solid rgba(61, 145, 255, 0.4); transition: all 0.3s;border-radius: 25px;min-height: 50px;font-size: 18px;}
.m-body .m-login .el-button:hover { border-color: #1d6dc3; opacity: 0.9;}
.m-body .m-login .el-input__inner { background: transparent; color: #333; font-size: 16px; border: none;}
.m-body .m-login .-icmy::before{font-size: 20px; color: #9caacb;}
.m-body .m-login .-iclock::before{font-size: 22px; color: #9caacb;}
.m-body .m-login .-ictag::before{font-size: 22px; color: #9caacb;}
.m-body .m-login .el-input__inner:hover { border-color: rgba(61, 145, 255, 0.9);}

.m-body .el-input--prefix .el-input__inner{padding-left: 10px;color: #333333}
input::placeholder{color:#999999 !important;}
.-wrapleft{width: 60%; text-align: center;display: flex; flex-direction: column; justify-content: center; align-items: center;}
.-wrapleft .-logo{width: 370px; height: 110px;background: url(../../assets/image/logo2.png) no-repeat center;background: cover;} 
.-wrapright{width: 42%; text-align: center;display: flex; flex-direction: column; justify-content: center; align-items: center;position: absolute;right: 0;background: #FFFFFF;height: 100%;top: 0;bottom: 0;}

/* 载入前 */
.-before .m-login { opacity: 0; transform: scale(0.9) translateX(80px) translateY(-40px)}
</style>
